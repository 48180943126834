function getParams() {
  const vars = {};
  const queryString = window.location.href.substring(
    window.location.href.indexOf("?") + 1
  );
  const urlParams = new URLSearchParams(queryString);
  var entries = urlParams.entries();
  for (let pair of entries) {
    vars[pair[0]] = pair[1];
  }
  return vars;
}

export default getParams;
