const getWebViewDetect = window => {
  const userAgent = window.navigator.userAgent
  const isAndroidWebView = () => Boolean(userAgent.match(/Android/i)) && Boolean(userAgent.match(/wv/i));
  const isWebView = isAndroidWebView
  return {
    isAndroidWebView,
    isWebView,
  }
};

export default getWebViewDetect;