import axios from "axios";

const submitTapToLogin = async (authInfo, email) => {
  try {
    const { data } = await axios.get(
      `/customer-api/v1/loginAttempts/${authInfo.loginAttemptSecret}`
    );
    if (data && data.verification && data.verification.approved) {
      return data;
    }
    return null;
  } catch (e) {
    throw new Error();
  }
};

export default submitTapToLogin;
