import axios from "axios";

const pollLoginQR = async authInfo => {
  if (!authInfo || !authInfo.loginAttemptSecret) {
    return null;
  }
  try {
    const req = await axios.get(
      `/customer-api/v1/loginAttempts/${authInfo.loginAttemptSecret}`
    );

    const { data } = req;
    if (data && data.verification) {
      return data;
    }
    return null;
  } catch (e) {
    throw new Error("There was an error logging in");
  }
};

export default pollLoginQR;
