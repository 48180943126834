/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  getErrorMessage,
  getMobileDetect,
  getParams,
  getWebViewDetect,
  submitTapToLogin,
  pollLoginQR,
} from "./utils";
import "./App.scss";
import {
  AuthTypeToggle,
  Bottom,
  DarkModeToggle,
  DevCounterDisplay,
  Expired,
  InAppPrompt,
  LoginError,
  Main,
  PoweredByDesktop,
  PoweredByMobile,
  QR,
  Seperator,
  TimeoutButton,
  Top,
  useInt
} from "@traitware/react-component-library"
import darkModeLogo from "@traitware/react-component-library/build/TW-logo-monochrome.png"
import lightModeLogo from "@traitware/react-component-library/build/blue-logo.png"
import whiteLogo from "@traitware/react-component-library/build/white-logo.png"
import "@traitware/react-component-library/build/index.es.css"
import setThemeColors from "./utils/setThemeColors";


const SESSION_TIMEOUT = 30;
const isDevEnv = process.env.NODE_ENV === "development"

const isMobile = getMobileDetect(window.navigator.userAgent).isMobile();
const isWebView = getWebViewDetect(window).isWebView();

const getDarkMode = () => {
  const localMode = localStorage?.getItem('login-darkmode') ?? 'off'
  if (localMode === 'on') {
    return true
  }
  if (localMode === 'off') {
    return false
  }
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return true
  }
  return false
}

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [application, setApplication] = useState();
  const [email, setEmail] = useState('')
  const [hasLogo, setHasLogo] = useState(false)
  const [hasBg, setHasBg] = useState(false)
  const [darkMode, setDarkMode] = useState(getDarkMode())
  const [authType, setAuthType] = useState(localStorage?.getItem("authType") || "qr")
  const [customTheme, setCustomTheme] = useState('')
  // Timers
  const sessionTimeout = useInt(() => { })
  const promptTimeout = useInt(() => { }, false)

  const [isExpired, setIsExpired] = useState(false);

  const resetMsg = () => {
    setError("")
    sessionTimeout.reset()
    promptTimeout.clear()
  }
  // initial setting Application
  useEffect(() => {
    const fetchData = async () => {
      try {
        let params = getParams();
        setHasBg(params.bg === 'true')
        const { theme } = params
        setCustomTheme(theme)
        setThemeColors(theme)
        // Fix for applicationName with spaces as those will be replaced + '+' in the URL
        params.applicationName = params.applicationName.replace(/\+/g, " ");
        setApplication(params);
        try {
          await axios.get(`/customer-api/v1/loginAttempts/${params.loginAttemptUuid}/logo?size=96`)
          setHasLogo(true)
        } catch (e) {
          setHasLogo(false)
        }
      } catch (e) {
        setError(getErrorMessage(e));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => {
      cancelAllCounters();
    };
  }, []);

  // Session Timeout check
  useEffect(() => {
    if (!application) {
      return
    }
    if (authType === 'qr') {
      checkQR()
    }
    if (sessionTimeout.current > SESSION_TIMEOUT * 2) {
      sessionTimeout.clear()
      setIsExpired(true)
    }
  }, [sessionTimeout.current])

  const cancelAllCounters = () => {
    sessionTimeout.clear()
    promptTimeout.clear()
  }

  // qr polling
  const checkQR = async () => {
    try {
      const d = await pollLoginQR(application);
      if (d) {
        cancelAllCounters();
        // check if saml application
        if (d.postUrl) {
          const { postUrl, postParameters } = d;
          const form = document.createElement("form");
          document.body.appendChild(form);
          Object.entries(postParameters).forEach(([name, value]) => {
            const input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", name);
            input.setAttribute("value", value);
            form.appendChild(input);
          });
          form.action = postUrl;
          form.method = "post";
          form.submit();
        } else if (d.verification.approved) {
          // redirect for OIDC and OAuth
          window.location = d.redirectUri;
        } else {
          window.location.reload();
        }
      }
    } catch (e) {
      cancelAllCounters();
      setError(getErrorMessage(e));
      try {
        await axios.delete(
          `/customer-api/v1/loginAttempts/${application.loginAttemptSecret}`
        );
      } catch (e) {
        setError(getErrorMessage(e));
      } finally {
      }
    }
  };

  // tap-to-login polling
  const pollDirectLogin = async () => {
    try {
      const d = await submitTapToLogin(application, email);
      if (d) {
        cancelAllCounters();
        // check if saml application
        if (d.postUrl) {
          const { postUrl, postParameters } = d;
          const form = document.createElement("form");
          document.body.appendChild(form);
          Object.entries(postParameters).forEach(([name, value]) => {
            const input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", name);
            input.setAttribute("value", value);
            form.appendChild(input);
          });
          form.action = postUrl;
          form.method = "post";
          form.submit();
        } else if (d.verification.approved) {
          // redirect for OIDC and OAuth
          window.location.href = d.redirectUri;
        } else {
          setIsExpired(true);
        }
      }
    } catch (e) {
      cancelAllCounters();
      try {
        await axios.delete(
          `/customer-api/v1/loginAttempts/${application.loginAttemptSecret}`
        );
      } catch (e) {
      } finally {
        setIsExpired(true);
      }
    }
  };

  const handleEmailSubmit = async (email) => {
    setError()
    setEmail(email)
    try {
      await axios.put(
        `/loginAttempts/${application.loginAttemptUuid}`,
        {
          emailAddress: email,
          loginAttemptUuid: application.loginAttemptUuid
        },
        {
          baseURL: "/customer-api/v1"
        }
      )
      sessionTimeout.clear()
      promptTimeout.start()
      localStorage?.setItem("login-email", email)
      setEmail(email)
    } catch (e) {
      setError(getErrorMessage(e))
    }
  }

  // TapToLogin polling
  useEffect(() => {
    if (promptTimeout.current > 0) {
      pollDirectLogin()
    }
    if (promptTimeout.current > SESSION_TIMEOUT) {
      promptTimeout.clear()
      sessionTimeout.start()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptTimeout.current])


  const cancelDirectLogin = async () => {
    try {
      setError()
      promptTimeout.clear()
      sessionTimeout.start()
      await axios.delete(
        `/customer-api/v1/loginAttempts/${application.loginAttemptSecret}`
      );
    } catch (e) {
      console.log('e', e)
      setError(getErrorMessage(e))
    } finally { }
  }

  const launchTraitwareApp = () => {
    cancelAllCounters();
    let url = "traitware://?loginAttemptUuid=";
    url = `${url}${application.loginAttemptUuid}&clientId=${application.clientId}&applicationType=${application.applicationType}`;
    window.location = url;
  };

  if (loading) {
    return null;
  }

  /**
   * Renders a Logo if one exists for the application
   * 
   * @returns {React.Component}
   */
  const Logo = () => {
    return hasLogo ?
      <img className="application-logo"
        src={`/customer-api/v1/loginAttempts/${application.loginAttemptUuid}/logo?size=96`}
        alt="application logo" /> :
      <div className="img-placeholder" />
  }

  if (application) {
    return (
      <div id="wrapper">
        {customTheme && hasBg &&
          <picture>
            <source media="(min-width: 1px)" srcSet={`/customer-api/v1/loginAttempts/${application.loginAttemptUuid}/bg.webp`} type="image/webp" alt="background" />
            <source media="(min-width: 1px)" srcSet={`/customer-api/v1/loginAttempts/${application.loginAttemptUuid}/bg.jpeg`} type="image/jpeg" alt="background" />
            <img className="fallback" src={`/customer-api/v1/loginAttempts/${application.loginAttemptUuid}/bg.jpeg`} alt="background" />
          </picture>
        }
        <div id="login-v2" className={darkMode ? "login-darkmode" : customTheme ? 'custom-background' : ''}>
          {isExpired && <Expired returnUri={application.returnUri} />}
          {isDevEnv && (
            <DevCounterDisplay>
              <p>SessionTimeout: {sessionTimeout.current}</p>
              <button onClick={sessionTimeout.clear}>clear</button>
              <button onClick={sessionTimeout.reset}>reset</button>
              <button onClick={sessionTimeout.start}>start</button>
              <hr />
              <p>PromptTimeout: {promptTimeout.current}</p>
              <button onClick={promptTimeout.clear}>clear</button>
              <button onClick={promptTimeout.reset}>reset</button>
              <button onClick={promptTimeout.start}>start</button>
              <hr />
            </DevCounterDisplay>
          )}
          <div>
            <Main>
              {isMobile ? (
                <React.Fragment>
                  <Top>
                    <Logo />
                    <h1 className={isWebView ? "mb-0" : ""}>
                      Sign in <br />
                      <span>
                        {application.applicationName}
                      </span>
                    </h1>
                    {!isWebView && promptTimeout.current === 0 && (
                      <div id="sso-button">
                        <button className="btn btn-sso" onClick={launchTraitwareApp}>
                          TraitWare Single Sign-On
                        </button>
                      </div>
                    )}
                  </Top>
                  {!isWebView && <Seperator />}
                  <Bottom>
                    <div>
                      {authType === "qr" ? (
                        <QR darkMode={darkMode} loginInfo={application} isMobile={true} />
                      ) : (
                        <InAppPrompt
                          handleEmailSubmit={handleEmailSubmit}
                          resetMsg={resetMsg}
                          promptTimeout={promptTimeout}
                          cancelDirectLogin={cancelDirectLogin} />
                      )}
                      {promptTimeout.current === 0 && (
                        <p className="log-into-app-first">
                          * Log into your TraitWare app first
                        </p>
                      )}
                    </div>
                    <br />
                    {application.enableTapToLogin !== 'false' && (
                      <AuthTypeToggle
                        authType={authType}
                        setAuthType={setAuthType}
                        resetMsg={resetMsg} />
                    )}
                    {error && <LoginError errorMsg={error} />}
                  </Bottom>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Top>
                    <div>
                      {application.enableTapToLogin === 'false' && <div className="four-rem"></div>}
                      <Logo />
                      <h1>
                        Sign in <br />
                        <span>
                          {application.applicationName}
                        </span>
                      </h1>
                      {application.enableTapToLogin !== 'false' && (
                        <AuthTypeToggle
                          authType={authType}
                          setAuthType={setAuthType}
                          resetMsg={resetMsg} />
                      )}
                    </div>
                    <PoweredByDesktop
                      darkMode={darkMode}
                      darkModeLogo={darkModeLogo}
                      lightModeLogo={lightModeLogo} />
                  </Top>
                  <Seperator />
                  <Bottom>
                    <div>
                      {authType === "qr" ? (
                        <QR darkMode={darkMode} loginInfo={application} isMobile={false} />
                      ) : (
                        <InAppPrompt
                          handleEmailSubmit={handleEmailSubmit}
                          resetMsg={resetMsg}
                          promptTimeout={promptTimeout}
                          cancelDirectLogin={cancelDirectLogin} />
                      )}
                      {promptTimeout.current === 0 && (
                        <p className="log-into-app-first">
                          * Log into your TraitWare app first
                        </p>
                      )}
                    </div>
                    {error && <LoginError errorMsg={error} />}
                  </Bottom>
                </React.Fragment>
              )}
            </Main>
            <PoweredByMobile logo={whiteLogo} />
            {sessionTimeout.current > SESSION_TIMEOUT ? (
              <TimeoutButton
                sessionTimeout={sessionTimeout}
                SESSION_TIMEOUT={SESSION_TIMEOUT}
                fetchLoginInfo={() => { window.location.reload() }}
                darkMode={darkMode}
              />
            ) : (
              !customTheme && <DarkModeToggle darkMode={darkMode} setDarkMode={setDarkMode} />
            )}
          </div>
        </div>
      </div>
    );
  }
  return null
}

export default App;
