const KEYS = {
  backgroundColor: { text: "Background Color", varName: '--login-background' },
  modalBackgroundColor: { text: "Modal Background Color", varName: '--login-modal-background' },
  buttonActiveBg: { text: "Active Button Color", varName: '--login-button-active-bg' },
  buttonActiveText: { text: "Active Button Text Color", varName: '--login-button-active-text' },
  buttonInactiveBg: { text: "Inactive Button Color", varName: '--login-button-inactive-bg' },
  buttonInactiveText: { text: "Inactive Button Text Color", varName: '--login-button-inactive-text' },
  textColor: {
    text: "Text Color", varName: '--login-text-color'
  },
  textColorMuted: { text: "Additional Text Color", varName: '--login-muted' },
}

const setThemeColors = (theme) => {
  if (!theme) {
    return
  }
  const [
    // eslint-disable-next-line no-unused-vars
    _, // first split chunk is empty, discard
    backgroundColor,
    buttonActiveBg,
    buttonActiveText,
    buttonInactiveBg,
    buttonInactiveText,
    modalBackgroundColor,
    textColor,
    textColorMuted,
  ] = theme.split('#');
  const customColors = {
    backgroundColor,
    buttonActiveBg,
    buttonActiveText,
    buttonInactiveBg,
    buttonInactiveText,
    modalBackgroundColor,
    textColor,
    textColorMuted
  }
  Object.keys(KEYS).forEach(k => {
    document.documentElement.style.setProperty(KEYS[k]?.varName, `#${customColors[k]}`);
  })
}

export default setThemeColors;
